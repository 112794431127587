import "core-js/modules/es.array.push.js";
import "core-js/modules/web.dom-exception.stack.js";
import "core-js/modules/es.typed-array.at.js";
import "core-js/modules/es.typed-array.find-last.js";
import "core-js/modules/es.typed-array.find-last-index.js";
import "core-js/modules/es.typed-array.set.js";
import "core-js/modules/es.typed-array.to-reversed.js";
import "core-js/modules/es.typed-array.to-sorted.js";
import "core-js/modules/es.typed-array.with.js";
// import routes from '../router/routes'
import { Divider, Button } from 'vant';
import { isCnIDCard } from '@/api/validator';
export default {
  name: 'login',
  data() {
    //身份证校验

    return {
      uploadUrl: '/api/upload',
      image: '',
      showSign: false,
      panelVisible: false,
      panelTitle: '',
      options: {
        penColor: '#000',
        minWidth: 1,
        //控制画笔最小宽度
        maxWidth: 1 //控制画笔最大宽度
      },

      isActive1: true,
      isActive2: false,
      isActive3: false,
      imgSrc: '',
      loading: false,
      sfzok: false,
      form: {
        sfz: '',
        ty: false
      },
      images: [{
        id: 1,
        url: 'https://image.baidu.com/search/down?tn=download&ipn=dwnl&word=download&ie=utf8&fr=result&url=https%3A%2F%2Fwww.vue365.cn%2Fwp-content%2Fuploads%2F2020%2F05%2F2020061801342061.png&thumburl=https%3A%2F%2Fimg0.baidu.com%2Fit%2Fu%3D424425158%2C1585263065%26fm%3D253%26fmt%3Dauto%26app%3D138%26f%3DJPEG%3Fw%3D1011%26h%3D342'
      }, {
        id: 2,
        url: 'https://image.baidu.com/search/down?tn=download&ipn=dwnl&word=download&ie=utf8&fr=result&url=https%3A%2F%2Fwww.vue365.cn%2Fwp-content%2Fuploads%2F2020%2F05%2F2020061801342061.png&thumburl=https%3A%2F%2Fimg0.baidu.com%2Fit%2Fu%3D424425158%2C1585263065%26fm%3D253%26fmt%3Dauto%26app%3D138%26f%3DJPEG%3Fw%3D1011%26h%3D342'
      }],
      htmls: '',
      rules: {
        idCardNumber: [{
          required: true,
          message: '身份证不能为空',
          trigger: 'blur'
        }, {
          validator: isCnIDCard,
          message: '身份证未能通过验证'
        }]
      }
    };
  },
  methods: {
    onSubmit(img) {
      console.log(img);
      this.updatavue(img.image);
      this.showSign = false;
      var id = this.$route.query.id;
      // this.$router.push({
      //   path: './testDo',
      //   query: { id: id },
      // });
    },

    onSubmit2() {
      var that = this;
      console.log(this.$data.form.sfz);
      that.$http.post('/api/ycf/Sign', {
        description: this.$data.image
      }, '服务器正在查询您的信息...').then(result => {
        var id = this.$route.query.id;
        this.$router.push({
          path: './testDo',
          query: {
            id: id
          }
        });
      }).catch(err => {
        console.log(err);
      });
    },
    afterUpload(url) {
      this.signImgUrl = url;
      this.showSign = false;
      this.$router.push('/index');
      //图片保存上传完毕进行修改个人签名图片路径数据
    },

    closeSign() {
      this.showSign = false;
    },
    getzqtys() {},
    onClear() {},
    sign() {
      this.showSign = true;
    },
    async updatavue(data) {
      //转成file文件
      let blobObj = this.base64ToBlob(data);
      var file = new File([blobObj], 'sign.png', {
        type: blobObj.type,
        lastModified: Date.now()
      });
      //此处为发送请求给后台获取图片路径
      this.uploadImg(file);
    },
    //base64转Blob
    base64ToBlob(base64Data) {
      // console.log(base64Data);
      let arr = base64Data.split(','),
        fileType = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        l = bstr.length,
        u8Arr = new Uint8Array(l);
      while (l--) {
        u8Arr[l] = bstr.charCodeAt(l);
      }
      return new Blob([u8Arr], {
        type: fileType
      });
    },
    uploadImg(file) {
      //上传签名图片
      const formData = new FormData();
      formData.append('name', 'qm');
      formData.append('type', 'type');
      formData.append('file', file);
      this.$http.postfile(this.uploadUrl, formData, '上传中').then(resData => {
        console.log(resData);
        this.image = resData.data;
      });
    }
  },
  mounted() {
    this.getzqtys();
  }
};